
/* Tailwind base styles */
@tailwind base;

/* Tailwind component styles */
@tailwind components;

/* Tailwind utility styles */
@tailwind utilities;

/* Apply Work Sans globally to the body */
@font-face {
  font-family: 'Work Sans Local';
  src: url('./assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


/* Reset select background */
select {
    background-image: unset;
}
